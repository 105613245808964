/**
* Template Name: Yummy
* Updated: Sep 18 2023 with Bootstrap v5.3.2
* Template URL: https://bootstrapmade.com/yummy-bootstrap-restaurant-website-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Set main reusable colors and fonts using CSS variables
# Learn more about CSS variables at https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
--------------------------------------------------------------*/
/* Fonts */
:root {
    --font-default: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-primary: "Amatic SC", sans-serif;
    --font-secondary: "Inter", sans-serif;
  }
  
  /* Colors */
  :root {
    --color-default: #212529;
    --color-primary: #ce1212;
    --color-secondary: #37373f;
  }
  
  /* Smooth scroll behavior */
  :root {
    scroll-behavior: smooth;
  }
  
  
  /*--------------------------------------------------------------
  # Sections & Section Header
  --------------------------------------------------------------*/
  #hero {
    overflow: hidden;
    padding: 80px 0;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  
  .section-bg {
    background-color: #eee;
  }
  
  .section-header {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-header h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #7f7f90;
    text-transform: uppercase;
    font-family: var(--font-default);
  }
  
  .section-header p {
    margin: 0;
    font-size: 48px;
    font-weight: 400;
    font-family: var(--font-primary);
  }
  
  .section-header p span {
    color: var(--color-primary);
  }
  
  
  /*--------------------------------------------------------------
  # Scroll top button
  --------------------------------------------------------------*/
  .scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: var(--color-primary);
    width: 44px;
    height: 44px;
    border-radius: 50px;
    transition: all 0.4s;
  }
  
  .scroll-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
  }
  
  .scroll-top:hover {
    background: #ec2727;
    color: #fff;
  }
  
  .scroll-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    inset: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
    transition: all 0.6s ease-out;
    width: 100%;
    height: 100vh;
  }
  
  #preloader:before,
  #preloader:after {
    content: "";
    position: absolute;
    border: 4px solid var(--color-primary);
    border-radius: 50%;
    animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  
  #preloader:after {
    animation-delay: -0.5s;
  }
  
  @keyframes animate-preloader {
    0% {
      width: 10px;
      height: 10px;
      top: calc(50% - 5px);
      left: calc(50% - 5px);
      opacity: 1;
    }
  
    100% {
      width: 72px;
      height: 72px;
      top: calc(50% - 36px);
      left: calc(50% - 36px);
      opacity: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  .hero {
    width: 100%;
    background-size: cover;
    position: relative;
    min-height: 60vh;
    padding: 160px 0 60px 0;
  }
  
  .hero h2 {
    font-size: 64px;
    font-weight: 700;
    margin-bottom: 20px;
    color: var(--color-secondary);
    font-family: var(--font-primary);
  }
  
  .hero h2 span {
    color: var(--color-primary);
  }
  
  .hero p {
    color: #4f4f5a;
    font-weight: 400;
    margin-bottom: 30px;
  }
  
  .hero .btn-book-a-table {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 36px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: var(--color-primary);
    box-shadow: 0 8px 28px rgba(206, 18, 18, 0.2);
  }
  
  .hero .btn-book-a-table:hover {
    background: rgba(206, 18, 18, 0.8);
    box-shadow: 0 8px 28px rgba(206, 18, 18, 0.45);
  }
  
  .hero .btn-watch-video {
    font-size: 16px;
    transition: 0.5s;
    margin-left: 25px;
    color: var(--font-secondary);
    font-weight: 600;
  }
  
  .hero .btn-watch-video i {
    color: var(--color-primary);
    font-size: 32px;
    transition: 0.3s;
    line-height: 0;
    margin-right: 8px;
  }
  
  .hero .btn-watch-video:hover {
    color: var(--color-primary);
  }
  
  .hero .btn-watch-video:hover i {
    color: rgba(206, 18, 18, 0.8);
  }
  
  @media (max-width: 640px) {
    .hero h2 {
      font-size: 36px;
    }
  
    .hero .btn-get-started,
    .hero .btn-watch-video {
      font-size: 14px;
    }
  }
  