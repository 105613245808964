/* a {
    color: #FFF;
  }
  a:hover {
     color: #00F
  } */

.headerImg {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 20px;
}

.profileHeader {
  padding-left: 20px;
  max-width: 300px;
  min-width: 150px;
}

/* .profileHeader:hover{
     background-color: #0b0b69;
  } */

.profileHeader-img {
  float: left;
  width: 30%;
  margin-right: 0%;
}
.profileHeader-name {
  float: left;
  width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: white;
  margin-top: 3px;
}
.logoutBtnHeader {
  cursor: pointer;
}

.dropdown-toggle::after {
  content: none;
}

.scrollStop {
  padding-right: 7px;
}

@media only screen and (max-width: 991px) {
  .profileHeader:hover {
    background-color: #2c2c6c;
  }
  .profileHeader {
    padding-left: 20px;
    padding-right: 20px;
    width: 1000px;
  }
}
